import axios from 'axios'
import { useQuery, useMutation } from '@tanstack/react-query'
import { IDA_TOKEN } from '@/constants/constant'

import queryClientContext from '@/contexts/QueryClientContext'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL || 'http://localhost:3000/v1' // will move it read from process env
  // Add any other headers or configurations you need
})

// Function to handle 403 and 404 errors
const handleErrors = error => {
  if (error.response) {
    const { status } = error.response
    if (status === 403) {
      // Handle 403 Forbidden
      // For example, you can redirect the user to a login page or show an error message
    } else if (status === 404) {
      // Handle 404 Not Found
      // For example, you can redirect the user to a 404 page or show an error message
    } else if (status === 401) {
      // Handle 401 Unauthorized
      // For example, you can redirect the user to a 401 page or show an error message
    }
  }
  throw error
}

// Function to retrieve the authentication token from storage (e.g., cookie or local storage)
const getAuthHeaders = () => {
  const idaToken = localStorage.getItem(IDA_TOKEN)
  if (idaToken) {
    return {
      Authorization: `Bearer ${idaToken}` // Include your authorization header here
    }
  }

  return {}
}

// Create a custom hook to handle API calls using React Query
export function useRequestService() {
  // Define a query function to fetch data with an authentication header
  const fetchDataWithAuth = async (key, url) => {
    try {
      const response = await api.get(url, {
        headers: getAuthHeaders()
      })
      return response.data
    } catch (error) {
      handleErrors(error)
    }
  }

  // Define a mutation function to send data to the server (e.g., POST, PUT) with an authentication header
  const postDataWithAuth = async ({ url, data, invalidateKeys = [] }) => {
    try {
      const response = await api.post(url, data, {
        headers: getAuthHeaders()
      })
      if (invalidateKeys.length) {
        invalidateKeys.forEach(key => {
          queryClientContext.invalidateQueries({ queryKey: key })
        })
      }
      return response.data
    } catch (error) {
      handleErrors(error)
    }
  }

  const putDataWithAuth = async ({ url, data, invalidateKeys = [] }) => {
    try {
      const response = await api.put(url, data, {
        headers: getAuthHeaders()
      })
      if (invalidateKeys.length) {
        invalidateKeys.forEach(key => {
          queryClientContext.invalidateQueries({ queryKey: key })
        })
      }
      return response.data
    } catch (error) {
      handleErrors(error)
    }
  }

  const deleteDataWithAuth = async ({ url, invalidateKeys = [] }) => {
    try {
      const response = await api.delete(url, {
        headers: getAuthHeaders()
      })
      if (invalidateKeys.length) {
        invalidateKeys.forEach(key => {
          queryClientContext.invalidateQueries({ queryKey: key })
        })
      }
      return response.data
    } catch (error) {
      handleErrors(error)
    }
  }

  // UseQuery hook to fetch data
  const useFetchQuery = (queryKey, url, config) =>
    useQuery(queryKey, () => fetchDataWithAuth(queryKey, url), config)

  // UseMutation hook to send data to the server
  const usePostQuery = () => useMutation({ mutationFn: postDataWithAuth })
  const usePutQuery = () => useMutation({ mutationFn: putDataWithAuth })
  const useDeleteQuery = () => useMutation({ mutationFn: deleteDataWithAuth })

  const createMutation = url =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation({
      mutationFn: async data => {
        try {
          const response = await api.post(url, data, {
            headers: getAuthHeaders()
          })
          return response.data
        } catch (error) {
          handleErrors(error)
        }
      }
    })

  return {
    useFetchQuery,
    usePostQuery,
    usePutQuery,
    useDeleteQuery,
    createMutation
  }
}
