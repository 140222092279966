export const NotesDataSearch = [
  {
    name: 'Untitled',
    icon: 'MdOutlineStickyNote2',
    date: 'Today',
  },
  {
    name: 'Acceleration',
    icon: 'MdOutlineStickyNote2',
    date: '30/07/2023',
  },
  {
    name: 'Bettle Story',
    icon: 'MdOutlineStickyNote2',
    date: 'Today',
  },
  {
    name: 'Compare banks',
    icon: 'MdOutlineStickyNote2',
    date: '20/07/2023',
  },
  {
    name: 'Decimal',
    icon: 'MdOutlineStickyNote2',
    date: '04/07/2023',
  },
]

export const IDA_TOKEN = 'ida-token'

export const ImportsStatuses = {
  CREATED: {
    key: 'CREATED',
    name: 'Created',
    classes: 'orange',
    className: 'bg-opacity-50 bg-orange-200 text-orange-500',
  },
  STARTED: {
    key: 'STARTED',
    name: 'Started',
    classes: 'orange',
    className: 'bg-opacity-50 bg-orange-200 text-orange-500',
  },
  FINISHED: {
    key: 'FINISHED',
    name: 'Finished',
    classes: 'green',
    className: 'bg-[#12B76A] bg-opacity-5 text-[#12B76A]',
  },
  ERROR: {
    key: 'ERROR',
    name: 'With Errors',
    classes: 'red',
    className: 'bg-opacity-50 bg-red-200 text-red-500',
  },
}

export const DocumentStatuses = {
  CREATED: {
    key: 'CREATED',
    name: 'Created',
    classes: 'orange',
    className: 'bg-opacity-50 bg-orange-200 text-orange-500',
  },
  STARTED: {
    key: 'STARTED',
    name: 'Started',
    classes: 'orange',
    className: 'bg-opacity-50 bg-orange-200 text-orange-500',
  },
  CRAWLING: {
    key: 'CRAWLING',
    name: 'Crawling',
    classes: 'volcano',
    className: 'bg-[#FD853A] bg-opacity-10 text-[#FD853A]',
  },
  CRAWLED: {
    key: 'CRAWLED',
    name: 'Crawled',
    classes: 'green',
    className: 'bg-[#12B76A] bg-opacity-5 text-[#12B76A]',
  },
  CRAWL_ERROR: {
    key: 'CRAWL_ERROR',
    name: 'Crawl Error',
    classes: 'red',
    className: 'bg-opacity-50 bg-red-200 text-red-500',
  },
  IMPORTING: {
    key: 'IMPORTING',
    name: 'In progress',
    classes: 'volcano',
    className: 'bg-[#FD853A] bg-opacity-10 text-[#FD853A]',
  },
  IMPORTED: {
    key: 'IMPORTED',
    name: 'Imported',
    classes: 'green',
    className: 'bg-[#12B76A] bg-opacity-5 text-[#12B76A]',
  },
  IMPORT_ERROR: {
    key: 'IMPORT_ERROR',
    name: 'Import Error',
    classes: 'red',
    className: 'bg-opacity-50 bg-red-200 text-red-500',
  },
  CHUNKED: {
    key: 'CHUNKED',
    name: 'Chunked',
    classes: 'volcano',
    className: 'bg-[#FD853A] bg-opacity-10 text-[#FD853A]',
  },
  GENERATE_EMBEDDINGS: {
    key: 'GENERATE_EMBEDDINGS',
    name: 'Generate Embeddings',
    classes: 'gold',
    className: 'bg-opacity-50 bg-amber-200 text-amber-500',
  },
  GENERATING_EMBEDDINGS: {
    key: 'GENERATING_EMBEDDINGS',
    name: 'Generating Embeddings',
    classes: 'gold',
    className: 'bg-opacity-50 bg-amber-200 text-amber-500',
  },
  EMBEDDING_GENERATED: {
    key: 'EMBEDDING_GENERATED',
    name: 'Embeddings Generated',
    classes: 'gold',
    className: 'bg-opacity-50 bg-amber-200 text-amber-500',
  },
  EMBEDDING_ERROR: {
    key: 'EMBEDDING_ERROR',
    name: 'Embedding Error',
    classes: 'red',
    className: 'bg-opacity-50 bg-red-200 text-red-500',
  },
  TRAINED: {
    key: 'TRAINED',
    name: 'Learned',
    classes: 'green',
    className: 'bg-[#12B76A] bg-opacity-5  text-[#12B76A]',
  },
}

export const DocumentStatusComponentOptionKeys = {
  DOCUMENT: 'DOCUMENT',
  IMPORT: 'IMPORT',
}

export const DocumentStatusComponentOptions = {
  DOCUMENT: DocumentStatuses,
  IMPORT: ImportsStatuses,
}

export const ActionTypes = {
  Prepend: 'Prepend',
  Add: 'Add',
  Edit: 'Edit',
  Update: 'Update',
  Delete: 'Delete',
  Cancel: 'Cancel',
  Clear: 'Clear',
  Archive: 'Archive',
  Rename: 'Rename',
  Logout: 'Logout',
  Reset: 'Reset',
  Share: 'Share',
}

export const HEADER_DETAILS = {
  backPath: '',
  pageTitle: 'Bots',
  pageNavigate: true,
  path: '/bots/customize',
  routeName: 'New Bot',
}

export const MAX_FILE_SIZE = 25 * 1024 * 1024 // 25mb

export const FORMAT_DATE_MMMM_D_YYYY = 'MM-DD-YYYY'

export const SORT_POPULARITY = Object.freeze({
  ASC: 'POPULARITY_ASC',
  DESC: 'POPULARITY_DESC',
})

export const getSettingsLeftPanelItems = () => [
  {
    name: 'Users',
    groups: [
      {
        title: 'Manage and Invite Users',
        icon: 'Users',
        current: '/settings?type=management',
        url: '/settings?type=management',
      },
    ],
  },
  // TODO: no remove apparance
  // {
  //   name: 'Appearance',
  //   groups: [
  //     {
  //       title: 'Appearance',
  //       icon: 'Palette',
  //       current: '/settings?type=appearance',
  //       url: '/settings?type=appearance',
  //     },
  //   ],
  // },
  {
    name: 'API Keys',
    groups: [
      {
        title: 'API Keys',
        icon: 'KeyRound',
        current: '/settings?type=api-keys',
        url: '/settings?type=api-keys',
      },
    ],
  },
  {
    name: 'Customize',
    groups: [
      {
        title: 'Customize',
        icon: 'Palette',
        current: '/settings?type=customize',
        url: '/settings?type=customize',
      },
    ],
  },
]

export const getAdminLeftPanelItems = () => [
  {
    name: 'Client',
    groups: [
      {
        title: 'Clients',
        icon: 'Users',
        current: '/admin/clients',
        url: '/admin/clients',
      },
    ],
  },
  {
    name: 'Plans',
    groups: [
      {
        title: 'Plans',
        icon: 'Layers',
        current: '/admin/plans',
        url: '/admin/plans',
      },
    ],
  },
  {
    name: 'Partner',
    groups: [
      {
        title: 'Partners',
        icon: 'Handshake',
        current: '/admin/partners',
        url: '/admin/partners',
      },
    ],
  },
]
